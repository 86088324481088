import { useCallback, useMemo, useState } from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ROUTE_PATH, STRINGS } from '../../../constants';
import OrderDetails from '../../../components/OrderDetails';
import EmptySearchResults from '../../../components/EmptySearchResults';
import Loader from '../../../features/Loader';
import useGetParcelParams from '../../../hooks/useGetParcelParams';
import {
  ERROR_BOUNDARY_TYPES,
  ErrorBoundary,
  useErrorBoundaryConfigurations,
} from '../../../features/ErrorBoundary';
import ShareModal from '../../../components/ShareModal/ShareModal';
import useBreakpoint from '../../../hooks/useBreakpoint';
import parcelSlice from '../../../redux/parcelSlice';

const TrackSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onBackClick = () => navigate(ROUTE_PATH.TRACK_PATH);
  const isSmallScreen = useBreakpoint('md');

  const { parcelCode, parcelNumber, postcode } = useGetParcelParams();

  const getErrorBoundaryConfig = useErrorBoundaryConfigurations();

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [parcel, setParcel] = useState(null);

  const watchParams = useMemo(
    () => [parcelNumber, postcode],
    [parcelNumber, postcode]
  );

  const fetchParcelFn = useCallback(async () => {
    try {
      if (parcelCode) {
        const data = await dispatch(
          parcelSlice.actions.fetchParcelByCode({ parcelCode, postcode })
        ).unwrap();

        setParcel(data);
      }
    } catch (error) {
      setParcel(null);

      throw error;
    }
  }, [dispatch, parcelCode, postcode]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignSelf: parcel ? 'baseline' : 'center',
        flexGrow: 1,
        width: '100%',
      }}
    >
      <Loader
        promiseFn={fetchParcelFn}
        pendingProps={{
          sx: {
            display: 'flex',
            justifyContent: 'center',
            flexGrow: 1,
            alignItems: 'center',
            p: 2,
            mt: { xs: -4, md: 0 },
          },
        }}
        watch={watchParams}
        errorComponent={error => (
          <ErrorBoundary
            config={getErrorBoundaryConfig[
              ERROR_BOUNDARY_TYPES.WE_HAVE_A_LITTLE_PROBLEM
            ](error.message)}
          />
        )}
      >
        {parcel ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1,
              p: 2,
            }}
          >
            <ShareModal
              parcel={parcel}
              open={isShareModalOpen}
              onClose={() => setIsShareModalOpen(false)}
            />
            <OrderDetails
              parcel={parcel}
              showBackButton={isSmallScreen}
              footerButtons={
                <Button
                  variant='outlined'
                  size='medium'
                  onClick={() => setIsShareModalOpen(true)}
                >
                  {STRINGS.SHARE_TRACKING_INFO}
                </Button>
              }
              onBackClick={onBackClick}
            />
          </Box>
        ) : (
          <EmptySearchResults sx={{ mt: { xs: 0, md: 5 }, px: 2 }} />
        )}
      </Loader>
    </Box>
  );
};

export default TrackSection;
