import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { Form } from 'react-final-form';
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  InputAdornment,
  List,
  Typography,
  useTheme,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { flow } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';

import { FIELDS } from '../../../constants/forms';
import {
  FOUND_REFERENCE_NUMBER,
  TRACK,
  TRACK_YOUR_PARCEL,
} from '../../../constants/strings';
import {
  trackParcelSchema,
  validateWithJoi,
} from '../../../helpers/validators';
import { replaceLetters } from '../../../utils/strings';
import { StringUtil } from '../../../utils';
import useGetParcelParams from '../../../hooks/useGetParcelParams';
import { useOverlay } from '../../../features/Overlay';
import parcelSlice from '../../../redux/parcelSlice';
import { FormHelpers, ParcelHelpers } from '../../../helpers';
import { FormInput } from '../../../components/FormInput';

const Sidebar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const overlay = useOverlay();
  const dispatch = useDispatch();

  const { parcelNumber, postcode } = useGetParcelParams();

  const handleFormSubmit = useCallback(
    async formValues => {
      const { parcelNumber, postcode } = formValues;

      if (parcelNumber) {
        try {
          overlay.show();

          const { parcelCode } = await dispatch(
            parcelSlice.actions.fetchParcelCodeByParcelNumber({
              parcelNumber,
              postcode,
            })
          ).unwrap();

          for (let key in localStorage) {
            if (key.startsWith('tracking/')) {
              localStorage.removeItem(key);
            }
          }

          localStorage.setItem(`tracking/${parcelCode}`, postcode);

          if (parcelCode) {
            navigate(`/parcels/${parcelCode}`);
          }
        } catch (error) {
          const { message, statusCode } = error;

          navigate(`/parcels`, {
            state: {
              error: { message, statusCode },
            },
          });
        } finally {
          overlay.hide();
        }
      }
    },
    [dispatch, navigate, overlay]
  );

  return (
    <Box>
      <Drawer
        anchor='left'
        variant='permanent'
        sx={{
          height: '100%',
        }}
        PaperProps={{
          sx: {
            width: { xs: '100%', md: 300, lg: 330 },
            borderRight: {
              xs: 'none',
              md: `1px solid ${theme.palette.primary.borderGray}`,
            },
            position: 'static',
            px: { xs: 2, md: 4 },
            height: {
              xs: 'auto',
              md: '100%',
            },
          },
        }}
      >
        <List
          sx={{
            display: { xs: 'flex' },
            flexDirection: 'column',
            height: {
              md: '100%',
            },
            mt: 1,
          }}
        >
          <Typography variant='h3' sx={{ pt: 2, pb: 2 }}>
            {TRACK_YOUR_PARCEL}
          </Typography>
          <Form
            onSubmit={handleFormSubmit}
            validate={values => validateWithJoi(values, trackParcelSchema)}
            initialValues={{
              parcelNumber,
              postcode,
            }}
            render={({ handleSubmit, form, values, invalid, submitting }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <FormInput
                    name={FIELDS.PARCEL_NUMBER.KEY}
                    label={FIELDS.PARCEL_NUMBER.LABEL}
                    placeholder={FIELDS.PARCEL_NUMBER.PLACEHOLDER}
                    required
                    fullWidth
                    InputProps={{
                      inputmode: 'numeric',
                      pattern: '[0-9]*',
                      endAdornment: !!values[FIELDS.PARCEL_NUMBER.KEY]
                        ?.length && (
                        <InputAdornment position='end'>
                          <IconButton
                            onClick={() => {
                              navigate('/parcels');
                              form.reset();
                            }}
                            edge='end'
                            aria-label='delete'
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fieldProps={{
                      parse: flow([
                        replaceLetters,
                        ParcelHelpers.normalizeParcelNumber,
                      ]),
                    }}
                  />
                  <FormInput
                    fullWidth
                    name={FIELDS.POSTCODE_SEARCH.KEY}
                    label={FIELDS.POSTCODE_SEARCH.LABEL}
                    placeholder={FIELDS.POSTCODE_SEARCH.PLACEHOLDER}
                    fieldProps={{
                      ...FormHelpers.fieldTrimOnBlur,
                      parse: StringUtil.trimFirstAndLastSpaces,
                    }}
                    required
                    maxLength={8}
                  />
                  <Button
                    variant='contained'
                    type='submit'
                    size='large'
                    disabled={invalid || submitting}
                  >
                    {TRACK}
                  </Button>
                  <Divider />
                  <Typography variant='body1' sx={{ mb: { xs: 4, md: 0 } }}>
                    {FOUND_REFERENCE_NUMBER}
                  </Typography>
                </Box>
              </form>
            )}
          />
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
