import { useCallback, useState } from 'react';
import { useForm } from 'react-final-form';
import { Button, Grid } from '@mui/material';
import { flow } from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useOverlay } from '../../../features/Overlay';
import { FORM, STRINGS } from '../../../constants';
import { StringUtil } from '../../../utils';
import parcelSlice from '../../../redux/parcelSlice';
import { useCustomSnackbar } from '../../../features/CustomSnackbar';
import { resetFields } from '../helpers';
import { FIELDS_TO_RESET_ON_PARCEL_CHANGE } from '../constants';
import { ErrorHelpers, FormHelpers, ParcelHelpers } from '../../../helpers';
import { FormInput } from '../../../components/FormInput';

const SearchParcelFields = ({ parcelNumber, postcode, setParcel, errors }) => {
  const overlay = useOverlay();
  const form = useForm();
  const dispatch = useDispatch();
  const { showError } = useCustomSnackbar();

  const [disabledSearch, setIsDisabledSearch] = useState(false);

  const handleFetch = useCallback(async () => {
    if (parcelNumber) {
      try {
        overlay.show();

        setIsDisabledSearch(true);
        resetFields(FIELDS_TO_RESET_ON_PARCEL_CHANGE, form);

        const { parcelCode, accessLevel } = await dispatch(
          parcelSlice.actions.fetchParcelCodeByParcelNumber({
            parcelNumber,
            postcode,
          })
        ).unwrap();

        if (ParcelHelpers.isGuestAccessLevel(accessLevel)) {
          setParcel(null);

          return;
        }

        if (parcelCode) {
          const data = await dispatch(
            parcelSlice.actions.fetchParcelByCode({
              parcelCode,
              postcode,
            })
          ).unwrap();

          setParcel(data);
        }
      } catch (error) {
        setParcel(null);

        if (
          !ErrorHelpers.isNotFoundError(error) &&
          !ErrorHelpers.isBadRequestError(error)
        ) {
          showError({ message: STRINGS.FETCHING_PARCEL_ERROR });
        }
      } finally {
        overlay.hide();
      }
    }
  }, [parcelNumber, overlay, form, dispatch, postcode, setParcel, showError]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md='auto'>
        <FormInput
          name={FORM.HELP_FIELDS.PARCEL_NUMBER.KEY}
          label={FORM.HELP_FIELDS.PARCEL_NUMBER.LABEL}
          fullWidth
          size='small'
          required
          onChange={() => {
            setIsDisabledSearch(false);
          }}
          sx={{ width: { md: 236, xs: '100%' } }}
          fieldProps={{
            parse: flow([
              StringUtil.replaceLetters,
              ParcelHelpers.normalizeParcelNumber,
            ]),
          }}
        />
      </Grid>
      <Grid item xs={12} md='auto'>
        <FormInput
          name={FORM.HELP_FIELDS.POSTCODE.KEY}
          label={FORM.HELP_FIELDS.POSTCODE.LABEL}
          fullWidth
          required
          size='small'
          maxLength={8}
          onChange={() => {
            setIsDisabledSearch(false);
          }}
          sx={{ width: { md: 176, xs: '100%' } }}
          fieldProps={{
            parse: StringUtil.trimFirstAndLastSpaces,
            ...FormHelpers.fieldTrimOnBlur,
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md='auto'
        sx={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <Button
          variant='contained'
          type='button'
          size='medium'
          onClick={handleFetch}
          disabled={
            !!errors?.[FORM.HELP_FIELDS.PARCEL_NUMBER.KEY] ||
            !!errors?.[FORM.HELP_FIELDS.POSTCODE.KEY] ||
            disabledSearch
          }
          sx={{ height: 40 }}
        >
          {STRINGS.SUBMIT}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SearchParcelFields;

SearchParcelFields.propTypes = {
  parcelNumber: PropTypes.string,
  postcode: PropTypes.string,
  setParcel: PropTypes.func.isRequired,
  errors: PropTypes.object,
};
