import { useCallback, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Box, Button, Grid, Paper, Typography, useTheme } from '@mui/material';
import { get, isEmpty, isNull } from 'lodash';

import ActionButton from '../../components/ActionButton';
import { Validators } from '../../helpers';
import {
  ERROR_BOUNDARY_TYPES,
  ErrorBoundary,
  useErrorBoundaryConfigurations,
} from '../../features/ErrorBoundary';
import { parcelsApis } from '../../apis';
import { getDayAndMonth } from '../../utils/dateAndTime';
import { useOverlay } from '../../features/Overlay';
import { useCustomSnackbar } from '../../features/CustomSnackbar';
import { FORM, STRINGS } from '../../constants';
import EmptySearchResults from '../../components/EmptySearchResults';
import OrderDetails from '../../components/OrderDetails';
import { HELP_TYPES } from './constants';
import ClaimTypeSelect from './components/ClaimTypeSelect';
import SearchParcelFields from './components/SearchParcelFields';
import ContactDetails from './components/ContactDetails';
import ClaimDetails from './components/ClaimDetails';
import {
  getValidationContext,
  isClaimInvalid,
  normalizeClaimData,
} from './helpers';
import SuccessScreen from './components/SuccessScreen';

const Help = () => {
  const theme = useTheme();
  const overlay = useOverlay();
  const { showError } = useCustomSnackbar();
  const getErrorBoundaryConfig = useErrorBoundaryConfigurations();

  const [submitted, setSubmitted] = useState(false);
  const [parcel, setParcel] = useState({});

  const deliveredDate = get(parcel, 'deliveryDetails.podDetails.podDate');

  const onFormSubmit = useCallback(
    async values => {
      try {
        overlay.show();

        const normalizedValues = normalizeClaimData({
          parcelCode: parcel.parcelCode,
          ...values,
        });

        await parcelsApis.createParcelClaim(normalizedValues);

        setSubmitted(true);
      } catch (error) {
        showError({ message: STRINGS.SEND_CLAIM_ERROR });
      } finally {
        overlay.hide();
      }
    },
    [overlay, parcel?.parcelCode, showError]
  );

  if (!isEmpty(parcel) && isClaimInvalid(deliveredDate)) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignSelf: 'center',
          flexGrow: 1,
        }}
      >
        <ErrorBoundary
          config={getErrorBoundaryConfig[
            ERROR_BOUNDARY_TYPES.CANNOT_PROCESS_CLAIM
          ](getDayAndMonth(deliveredDate))}
          sx={{ maxWidth: '447px', alignSelf: 'center' }}
        />
      </Box>
    );
  }

  if (submitted) {
    return <SuccessScreen />;
  }

  return (
    <Box
      sx={{
        py: { xs: 2 },
        p: { md: 4 },
        background: theme.palette.primary.backgroundLightGray,
        flexGrow: 1,
      }}
    >
      <Grid
        container
        sx={{
          justifyContent: 'center',
          mt: 1,
        }}
      >
        <Grid item>
          <Typography variant='h2' sx={{ m: 2, mt: 0 }}>
            {STRINGS.HOW_CAN_WE_HELP}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0} alignItems='center' justifyContent='center'>
        <Grid
          item
          xs={12}
          md={9}
          lg={7}
          xl={6}
          sx={{ maxWidth: { md: '562px' } }}
        >
          <Form
            onSubmit={onFormSubmit}
            validate={values =>
              Validators.validateWithJoi(values, Validators.helpParcelSchema, {
                context: getValidationContext(values.reasonForContact),
              })
            }
          >
            {({ values, errors, invalid, handleSubmit }) => {
              const isParcelIssueSelected =
                get(values, FORM.HELP_FIELDS.HELP_TYPE.KEY) ===
                HELP_TYPES.ISSUE;
              const contactDetails = get(
                values,
                FORM.HELP_KEYS.CONTACT_DETAILS
              );
              const hasContactDetails =
                contactDetails?.name && contactDetails?.email;
              const isContactDetailsValid = !errors.contactDetails;
              const issueDetailsAvailable =
                hasContactDetails && isContactDetailsValid;

              return (
                <form onSubmit={handleSubmit}>
                  <Paper sx={{ p: 2, mb: 2 }}>
                    <Typography variant='h3' sx={{ mb: 1 }}>
                      {STRINGS.CHOOSE_ONE_OF_OPTIONS}
                    </Typography>
                    <Field name={FORM.HELP_FIELDS.HELP_TYPE.KEY}>
                      {({ input }) => (
                        <Grid
                          container
                          spacing={2}
                          sx={{ mt: 1, alignItems: 'stretch' }}
                        >
                          <Grid container item md={6} xs={12}>
                            <ActionButton
                              selected={input.value === HELP_TYPES.CHAT}
                              title={STRINGS.CONTACT_US}
                              bodyText={STRINGS.GET_ANSWER_WITH_CHAT}
                              cardOnclick={() => {
                                input.onChange(HELP_TYPES.CHAT);
                              }}
                              disabled
                              additionalInfo={false}
                            />
                          </Grid>
                          <Grid container item md={6} xs={12}>
                            <ActionButton
                              selected={input.value === HELP_TYPES.ISSUE}
                              title={STRINGS.PARCEL_ISSUE}
                              bodyText={STRINGS.WE_CAN_HELP_PARCEL_ISSUE}
                              additionalInfo={false}
                              cardOnclick={() => {
                                input.onChange(HELP_TYPES.ISSUE);
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Field>
                  </Paper>
                  {isParcelIssueSelected && (
                    <Paper sx={{ p: 2, pb: 3, mb: 2 }}>
                      <Typography variant='h3' sx={{ mb: 1 }}>
                        {STRINGS.ENTER_YOUR_PARCEL_NUMBER}
                      </Typography>
                      <Box sx={{ mt: 3 }}>
                        <SearchParcelFields
                          parcelNumber={values.parcelNumber}
                          postcode={values.postCode}
                          setParcel={setParcel}
                          errors={errors}
                        />
                      </Box>
                    </Paper>
                  )}
                  {isParcelIssueSelected && !isEmpty(parcel) && (
                    <>
                      <OrderDetails
                        parcel={parcel}
                        summaryTitle={STRINGS.YOUR_PARCEL_SUMMARY}
                        trackingImages={parcel?.parcelEvents[0]?.images}
                        showBackButton={false}
                        isFullView={false}
                      />
                      <ClaimTypeSelect />
                      {values.reasonForContact && (
                        <>
                          <ContactDetails />
                          <ClaimDetails
                            issueType={values.reasonForContact}
                            isAvailable={issueDetailsAvailable}
                          />
                          <Box
                            sx={{
                              ml: 'auto',
                              width: 'fit-content',
                              pr: { xs: 2, md: 0 },
                            }}
                          >
                            <Button
                              variant='contained'
                              type='submit'
                              disabled={invalid}
                            >
                              {STRINGS.SUBMIT}
                            </Button>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </form>
              );
            }}
          </Form>
          {isNull(parcel) && <EmptySearchResults />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Help;
